<template>
    <div style="font-size: 8px">
        <div v-for="position in row.item.positions_data"
             :key="position.id"
        >
            <div v-if="position.article_id">
                <a href="#"
                   @click="$root.$children[0].openModal('article-modal', {id: position.article_id})"
                >{{ position.name }} ({{ position.sku }}) {{ position.count }}x{{ position.price }}</a>
            </div>
            <div v-else>{{ position.name }} ({{ position.sku }}) {{ position.count }}x{{ position.price }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': [Function],
    },
}
</script>